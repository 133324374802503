@import '/src/assets/styles/colors';

.services{

    .clients-hero-section{
        background: $primary-color-purple;
        min-height: 1400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 60px 0 60px 0;

        .usable-width{
            display: flex;
            flex-direction: column;
            width: var(--usable-width);
            padding: 0px 0px 30px 0px;

            .hero-header{
                color: $shades-white;
                max-width: 90%;
                display: block;
                margin: 30px auto 30px auto;
            }

            .services-container{
                display: flex;
                flex-wrap: wrap;
                column-gap: 30px;
                padding: 0px 0px 30px 0px;
                justify-content: center;
                align-items: stretch;
                margin: 30px auto 0px auto;

                .service-card{
                    flex: 45% 0;
                    margin: 15px 0px 15px 0px;
                    @media only screen and (min-device-width: 768px)  and (max-device-width: 1024px)  and (-webkit-min-device-pixel-ratio: 1) {
                        //IPAD
                        margin: 15px;
                    }
                    padding: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background: $shades-white;
                    border: 1px solid #0500B6;
                    box-sizing: border-box;
                    border-radius: 6px;
                    min-height: 310px;
                    min-width: min(540px, 100%);

                    .service-card-header{
                        margin: 15px 0px 15px 0px;
                        color: $primary-color-purple;
                    }

                    .service-card-description{
                        margin: 15px 0px 15px 0px;
                        text-align: center;
                        color: $shades-dark-liver;
                        @media (max-width:600px){
                            text-align: left;
                        }
                    }

                    .service-card-price{
                        margin: 15px 0px 15px 0px;
                        color: $shades-dark-liver;
                        @media (max-width:600px){
                            text-align: left;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .grants-waiting-section{
        min-height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 60px 0 60px 0;

        .usable-width{
            display: flex;
            width: var(--usable-width);
            flex-wrap: wrap;
            column-gap: 10%;
            padding: 15px 0px 15px 0px;

            .service-container-half{
                flex: 45% 1;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: min(350px, 100%);
                margin: 15px 0px 15px 0pxs;

                &.service-description{
                    margin: auto;
                }
                .service-text-div{
                    display: flex;
                    flex-direction: column;
                    padding: 9px 0px 9px 0px;

                    .service-header{
                        margin: 9px 0px 9px 0px;;
                        text-align: left;
                        color: $primary-color-purple;
                        @media (max-width:600px){
                            text-align: center;
                        }

                    }

                    .service-description{
                        margin: 9px 0px 9px 0px;;
                        color: $shades-dark-liver;
                    }
                }

                .service-artwork{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: fit-content;
                    max-height: 100%;
                    margin: 15px 0px 15px 0pxs;

                    .ngo-svg{
                        width: 100%;
                        max-width: 480px;
                        min-width: min(350px, 100%);
                    }

                    .artist-svg{
                        width: 100%;
                        max-width: 480px;
                        min-width: min(350px, 100%);
                    }
                }
            }

            .service-container-half.artwork{
                @media (max-width:1025px){
                    order: 1;
                }
            }
            .service-container-half.service-description{
                @media (max-width:1025px){
                    order: 2;
                }
            }
        }
    }
}
