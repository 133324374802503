@import '/src/assets/styles/colors';

.homepage{
    .homepage-hero{
        background: $primary-color-purple;
        width: 100%;
        min-height: 700px;
        position: static;
        color: $shades-white;
        display: flex;
        vertical-align: middle;
        padding: var(--homepage-padding);

        .usable-width{
            display: flex;
            width: var(--usable-width);
            flex-wrap: wrap;
            column-gap: 30px;
            margin: auto;

            .service-container-half{
                flex: 45% 1;
                display: flex;
                align-items: center;
                min-width: min(350px, 100%);

                .service-description{
                    margin: auto;
                }
                .service-text-div{
                    display: flex;
                    flex-direction: column;
                    padding: 15px 0px 15px 0px;

                    .service-header{
                        margin: 15px 0px 15px 0px;
                        text-align: left;
                        @media (max-width:600px){
                            text-align: center;
                        }
                    }

                    .service-description{
                        margin: 15px 0px 15px 0px;
                        @media (max-width:600px){
                            text-align: center;
                        }
                    }

                    .cta{
                        margin: 45px 0px 45px 0px;

                        @media (max-width:1025px){
                            display: flex;
                            justify-content: center;;
                        }
                    }
                }
            }

            .service-container-half.service-description{
                order: 1;
                justify-content: flex-start;

                @media (max-width:1025px){
                    order: 2;
                }
            }

            .service-container-half.artwork{
                order: 2;
                justify-content: flex-end;
                width: 100%;

                @media (max-width:1025px){
                    order: 1;
                }
            }
        }

    }

    .focus-craft{
        background: $secondary-color-lavender-web;
        width: 100%;
        min-height: 390px;
        color: $primary-color-blue;
        display: flex;
        vertical-align: middle;
        justify-content: space-evenly;
        padding: 60px 0px 60px 0px;

        .usable-width{
            display: flex;
            width: var(--usable-width);
            align-items: center;
            flex-direction: column;
            justify-content: space-evenly;

            .focus-craft-h{
                width: 100%;
                margin: 15px 0px 30px 0px;
            }

            .services-row{
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .service-item{
                    flex: 15% 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 9px 0px 9px 0px;
                    margin: 0px 5px 0px 5px;

                    p{
                        text-align: center;
                        margin: 9px 0px 9px 0px;
                        font-weight: 600;
                    }
                }

                .service-item:hover {
                    background-color: white;
                    border-radius: 6px;
                    transition: all .3s ease-out;
                    box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.1);
                    cursor: pointer;
                }
            }
        }
    }

    .seek-funding{
        background: $shades-white;
        width: 100%;
        min-height: 470px;
        display: flex;
        vertical-align: middle;
        justify-content: space-evenly;
        padding: 60px 0px 60px 0px;

        .usable-width{
            display: flex;
            width: var(--usable-width);
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            margin: auto;
            flex-wrap: wrap;


            .service-container-half{
                flex: 45% 1;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: min(350px, 100%);

                .service-description{
                    margin: auto;
                }
                .service-text-div{
                    display: flex;
                    flex-direction: column;
                    padding: 15px 0px 15px 0px;

                    .service-header{
                        margin: 15px 0px 15px 0px;
                        text-align: left;
                        color: $primary-color-purple;
                        @media (max-width:600px){
                            text-align: center;
                        }
                    }

                    .service-description{
                        margin: 15px 0px 15px 0px;
                        color: $shades-dark-liver;
                    }
                }
            }

            .service-container-half.service-description{
                order: 1;
                @media (max-width:1025px){
                    order: 2;
                }
            }

            .service-container-half.artwork{
                order: 2;
                @media (max-width:1025px){
                    order: 1;
                }
            }

        }


    }

    .community-belief{
        background: $primary-color-purple;
        width: 100%;
        min-height: fit-content;
        padding: 60px 0 60px 0;
        color: $shades-white;
        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;

        .usable-width{
            display: flex;
            width: var(--usable-width);
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 15px 0px 15px 0px;

            .community-belief-header{
                margin: 15px 0px 15px 0px;

            }
            .community-belief-description{
                text-align: center;
                max-width: max(60%, 800px);
                margin: 15px 0px 15px 0px;
                @media (max-width:600px){
                    text-align: left;
                }
            }

            .community-belief-clients{
                width: 100%;
                display: flex;
                column-gap: 30px;
                padding: 0px 0px 50px 0px;
                height: fit-content;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                @media (max-width:1025px){
                    padding: 20px 0px 50px 0px;
                }

                .community-belief-client{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 15px 0px 15px 0px;
                    margin: 50px 0px 50px 0px;
                    @media only screen and (min-device-width: 768px)  and (max-device-width: 1024px)  and (-webkit-min-device-pixel-ratio: 1) {
                        //IPAD
                        margin: 50px auto;
                    }
                    width: 100%;

                    .commmunity-belief-client-artwork-div{
                        width: 100%;
                        border-radius: 6px;
                        background: $secondary-color-lavender-blue;
                        width: 100%;
                        height: 370px;

                        .community-belief-client-artwork{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 100%;
                            margin: 0px auto 15px auto;

                            .ngo-artwork{
                                width: 350px;
                                max-height: min(100%, 370px);
                            }

                            .artist-artwork{
                                width: 350px;
                                max-height: min(100%, 370px);
                            }

                            .small-business-artwork{
                                width: 350px;
                                max-height: min(100%, 370px);
                            }
                        }
                    }

                    .community-belief-client-type{
                        margin: 30px auto auto auto;
                    }
                }
            }

            .community-belief-client:hover > .commmunity-belief-client-artwork-div{
                background-color: white;
                box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.225);
                cursor: pointer;
                transition: all 0.3s ease-out;
            }

            .community-belief-cta{
                margin: 60px auto 30px auto;
            }

            .community-belief-clients > .community-belief-client {
                flex: 30% 0;
                margin-bottom: 15px;
            }
        }

    }

    .community-belief > .usable-width {
        flex: inherit;
    }
}
