@import '/src/assets/styles/colors';

.clients{
    .clients-hero-section{
        background: $primary-color-purple;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 60px 0 60px 0;

        .usable-width{
            display: flex;
            flex-direction: column;
            width: var(--usable-width);
            color: $shades-white;
            padding: 10px 0px 10px 0px;

            .hero-header{
                max-width: 90%;
                display: block;
                margin: 10px auto 10px auto;
            }

            .hero-subheader{
                max-width: 85%;
                display: block;
                margin: 10px auto 10px auto;
                text-align: center;
                overflow-wrap:break-word;
            }
        }
    }

    #ngo-section{
        background: $secondary-color-lavender-web;
    }

    #artist-section{
        background: $shades-white;
    }

    #small-business-section{
        background: $secondary-color-lavender-web;
    }

    .service-section{
        min-height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 60px 0 60px 0;

        .usable-width{
            display: flex;
            width: var(--usable-width);
            flex-wrap: wrap;
            column-gap: 10%;

            .service-container-half{
                flex: 45% 1;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: min(350px, 100%);

                &.service-description{
                    margin: auto;
                }
                .service-text-div{
                    display: flex;
                    flex-direction: column;
                    padding: 9px 0px 9px 0px;

                    .service-header{
                        margin: 9px 0px 9px 0px;
                        text-align: left;
                        color: $primary-color-purple;
                        @media (max-width:1025px){
                            text-align: center;
                        }
                    }

                    .service-description{
                        margin: 9px 0px 9px 0px;
                        color: $shades-dark-liver;
                    }
                }

                .service-artwork{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: fit-content;
                    max-height: 100%;

                    .ngo-svg{
                        width: 100%;
                        max-width: 580px;
                        min-width: min(350px, 100%);
                    }

                    .artist-svg{
                        width: 100%;
                        max-width: 480px;
                        min-width: min(350px, 100%);
                        max-height: min(250px, 100%);
                    }
                }
            }

            .service-container-half.artwork{
                @media (max-width:960px){
                    order: 1;
                }
            }
            .service-container-half.service-description{
                @media (max-width:960px){
                    order: 2;
                }
            }
        }
    }
}
