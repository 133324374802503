@import '/src/assets/styles/colors';

.about{

    .about-hero-section{
        background: $primary-color-purple;
        min-height: 560px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 60px 0 60px 0;
        color: $shades-white;

        .usable-width{
            display: flex;
            flex-direction: column;
            width: var(--usable-width);
            color: $shades-white;
            justify-content: center;
            align-items: center;
            padding: 30px 0px 30px 0px;

            .process-section{
                display: flex;
                color: $shades-white;
                width: 100%;
                flex-wrap: wrap;
                padding: 30px 0px 30px 0px;
                margin: 30px 0px 30px 0px;

                .process-step{
                    flex: 20% 1;
                    margin: 30px 0px 30px 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 7.5px 0px 7.5px 0px;
                    @media (max-width:1025px){
                        flex: 40% 1;
                        padding: 7.5px 15px 7.5px 15px;
                    }
                    @media (max-width:960px){
                        flex: 100% 1;
                    }

                    .process-step-header{
                        margin: 7.5px 0px 7.5px 0px;
                        color: $shades-white;
                    }

                    .process-step-description{
                        margin: 7.5px 0px 7.5px 0px;
                        text-align: justify;
                    }
                }

                .process-arrow{
                    flex: 5% 0;
                    position: relative;
                    top: 100px;
                    display: flex;
                    justify-content: center;
                    height: 5px;
                    @media (max-width:1025px){
                        display: none;
                    }
                }
            }
        }
    }

    .who-we-are-section{
        min-height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 60px 0 60px 0;

        .usable-width{
            display: flex;
            width: var(--usable-width);
            flex-wrap: wrap;
            column-gap: 10%;

            .service-container-half{
                flex: 45% 1;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: min(350px, 100%);

                &.service-description{
                    margin: auto;
                }
                .service-text-div{
                    display: flex;
                    flex-direction: column;
                    padding: 9px 0px 9px 0px;

                    .service-header{
                        margin: 9px 0px 9px 0px;;
                        text-align: left;
                        color: $primary-color-purple;
                        @media (max-width:600px){
                            text-align: center;
                        }
                    }

                    .service-description{
                        margin: 9px 0px 9px 0px;;
                        color: $shades-dark-liver;
                    }
                }

                .service-artwork{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: fit-content;
                    max-height: 100%;

                    .ngo-svg{
                        width: 100%;
                        max-width: 480px;
                        min-width: min(350px, 100%);
                    }

                    .artist-svg{
                        width: 100%;
                        max-width: 480px;
                        min-width: min(350px, 100%);
                    }
                }

            }

            .service-container-half.artwork{
                @media (max-width:1025px){
                    order: 1;
                }
            }
            .service-container-half.service-description{
                @media (max-width:1025px){
                    order: 2;
                }
            }
        }
    }

    #how-we-started-section{
        padding: 0;
    }

    .our-mission-section {
        background: $primary-color-purple;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 60px 0 60px 0;
        color: $shades-white;

        .usable-width{
            display: flex;
            flex-direction: column;
            padding: 7.5px 0px 7.5px 0px;
            width: var(--usable-width);
            color: $shades-white;
            justify-content: center;
            align-items: center;

            div{
                margin: 7.5px 0px 7.5px 0px;
            }

            @media (max-width:600px){
                text-align: center;
            }
        }

    }

    .achievements-section{
        background: $secondary-color-lavender-web;
        min-height: 480px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 60px 0 60px 0;

        .usable-width{
            display: flex;
            flex-direction: column;
            // row-gap: 60px;
            padding: 0px 0 30px 0;
            width: var(--usable-width);
            justify-content: center;
            align-items: center;

            .achievements-header{
                color: $primary-color-purple;
                margin: 30px 0 30px 0;
            }

            .achievements-valuation{
                display: flex;
                width: 100%;
                justify-content: space-between;
                flex-wrap: wrap;
                padding: 15px;
                margin: 30px 0 0px 0;


                .achievements-milestone{
                    flex: 20% 1;
                    background: $shades-white;
                    border: 1px solid rgba(95, 46, 234, 0.5);
                    box-sizing: border-box;
                    border-radius: 15px;
                    display: flex;
                    flex-direction: column;
                    height: 140px;
                    justify-content: space-evenly;
                    max-width: max(225px, 100%);
                    min-width: min(150px, 100%);
                    margin: 15px;

                    .achievement-value{
                        color: $primary-color-purple;
                        text-align: center;

                    }

                    .achievement-source{

                    }
                }
            }
        }
    }

    .community-section{
        background: $shades-white;
        min-height: 480px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 60px 0 60px 0;

        .usable-width{
            display: flex;
            flex-direction: column;
            width: var(--usable-width);
            justify-content: center;
            align-items: center;
            @media only screen and (min-device-width: 768px)  and (max-device-width: 1024px)  and (-webkit-min-device-pixel-ratio: 1) {
                padding: 0 calc((100% - (255px * 3)) / 4);
            }

            .community-header{
                color: $primary-color-purple;
            }

            .community-companies{
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                flex-direction: row;
                padding: 30px 50px 30px 50px;
                column-gap: 30px;

                .empty-div{
                    width: 30px;
                }


                .community-company{
                    flex: 0 0 250px;
                    width: 250px;
                    height: 250px;
                    border-radius: 100%;
                    border: 2px solid #E6E6F8;
                    padding: 20px;
                    display: flex;
                    justify-content: center;
                    margin: 15px 0px 15px 0px;
                    @media (max-width:600px){
                        max-width: 250px;
                        min-width: 250px;
                    }

                    .community-logo{
                        max-width: 100%;
                        max-height: 100%;
                        height: auto;
                        margin: auto;
                        filter: saturate(0%);

                        &.bradius50{
                            border-radius: 50%;
                        }

                        &.bradius25{
                            border-radius: 25%;
                        }

                        &.blackBg{
                            background: black;
                        }
                    }
                }
            }
        }
    }
}
