@import '/src/assets/styles/colors';

.contact-form-section{
    background: $secondary-color-lavender-blue;
    min-height: 1070px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 0 60px 0;

    .usable-width{
        display: flex;
        flex-direction: column;
        padding: 7.5px 0px 7.5px 0px;
        width: var(--usable-width);

        .contact-form-header{
            margin: 7.5px auto 7.5px auto;
            color: $primary-color-blue;
            max-width: max(40%, 300px);
            display: block;
            margin: auto;
            @media (max-width:1025px){
                text-align: center;
            }
        }

        .contact-form-subheader{
            color: $primary-color-blue;
            max-width: max(40%, 300px);
            display: block;
            margin: 15px auto 7.5px auto;
        }

        .contact-form{
            margin: 45px auto 7.5px auto;
            width: min(100%, 900px);
            display: flex;
            flex-direction: column;
            padding: 25px 45px 25px 45px;
            background: $shades-white;
            text-align: left;
            color: $shades-dark-liver;
            @media (max-width:1025px){
                padding: 25px 25px 25px 25px;
            }

            .contact-form-cta{
                justify-content: center;
                margin: auto;
                @media (max-width:600px){
                    display: flex;
                    justify-content: center;
                }

                .btn-primary-regular{
                    border: none;
                }
            }

            div{
                margin: 25px 0px 25px 0px;
            }
        }
    }
}

.form-input{
    height: 40px;
    width: 100%;
    border: 2px solid $shades-silver-metallic;
    border-radius: 6px;
    padding: 9px 16px 9px 16px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    transition: border 0.5s ease;

    &:focus{
        border: 2px solid $primary-color-blue;
        outline: none;
    }

    &:required:invalid.error{
        border: 2px solid #CD5D3D;
    }
}

.form-long-message{
    height: 200px;
    min-height: 50px;
    max-width: 100%;
    min-width: 100%;
}

.hidden {
    display: none;
}

.purple-text{
    color: $primary-color-purple;
}
