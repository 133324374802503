@import '/src/assets/styles/colors';
@import "~bootstrap/scss/bootstrap";

.testimonials {
    background: $shades-white;
    min-height: 430px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0 30px 0;

    .usable-width{
        display: flex;
        width: var(--usable-width);
        margin: auto;

        .carousel.slide{
            width: 100%;
        }

        .carousel-container{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            padding: 60px;
            row-gap: 30px;

            .testimonial-left-half{
                flex: 12% 0;
                display: flex;
                flex-direction: column;
                row-gap: 15px;
                align-items: center;
                color: $shades-dark-liver;
                justify-content: center;
                text-align: center;
            }

            .empty-div{
                flex: 5% 0;
                height: 30px;
            }

            .testimonial-description{
                flex: 80% 0;
                text-align: justify;
                font-style: italic;
                display: flex;
                align-items: center;
                color: $shades-dark-liver;
                width: 100%;
                @media (max-width:600px){
                    // flex: 80% 0;
                }
            }
        }
    }
}

.testimonial-entity{
    text-align: center;
}

.carousel-button {
    border: none;
    cursor: pointer;
}

.carousel-control-prev{
    justify-content: flex-start!important;
}

.carousel-control-next{
    justify-content: flex-end!important;
}
