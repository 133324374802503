@import '/src/assets/styles/colors';

.footer{
    background: $secondary-color-blue-pantome;
    min-height: 380px;
    display: flex;
    vertical-align: middle;
    justify-content: space-evenly;
    color: $shades-white;
    padding: 60px 0 60px 0;

    .usable-width{
        display: flex;
        width: var(--usable-width);
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        margin: auto;
        flex-wrap: wrap;
        padding: 25px 0px 25px 0px;

        .footer-left-half{
            margin: 25px 0px 25px 0px;
            display: flex;
            flex-direction: column;
            flex: 45% 0;
            padding: 10px 0px 10px 0px;
            @media (max-width:600px){
                flex: 100% 1;
                flex-wrap: wrap;
            }

            .logo-container{
                @media (max-width:600px){
                    display: flex;
                    justify-content: center;
                }
            }

            .business-text-container{
                margin: 10px 0px 10px 0px;
                display: flex;
                flex-direction: column;
                padding: 10px 0px 10px 0px;

                .business-subtitle{
                    margin: 10px 0px 10px 0px;
                    @media (max-width:600px){
                        display: flex;
                        justify-content: center;
                    }
                }
                .business-subtitle-cta{
                    margin: 10px 0px 10px 0px;
                    max-width: 60%;
                    @media (max-width:600px){
                        max-width: 90%;
                        display: flex;
                        justify-content: center;
                        margin: auto;
                        text-align: center;
                    }
                }
            }
            .business-social-links {
                margin: 30px 0px 30px 0px;
                display: flex;
                flex-direction: row;
                padding: 0px 0px 0px 0px;
                column-gap: 15px;
                justify-content: flex-start;
                @media (max-width:600px){
                    justify-content: space-evenly;
                }

                .social-circle-link{
                    margin: 0px 0px 0px 0px;
                    @media only screen and (min-device-width: 768px)  and (max-device-width: 1024px)  and (-webkit-min-device-pixel-ratio: 1) {
                        //IPAD
                        margin: 0px 15px 0px 0px;
                    }
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: $shades-white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .divider{
            flex: 0 0 auto;
            border: 1px solid #FFFFFF;
            height: 400px;
            @media (max-width:960px){
                display: none;
            }
        }

        .footer-right-half{
            flex: 45% 0;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            margin: 25px 0px 25px 0px;
            @media (max-width:600px){
                flex: 100% 1;
                flex-wrap: wrap;
            }

            .business-sca{
                display: flex;
                @media (max-width:600px){
                    justify-content: center;
                }

                .business-sca-column{
                    flex: 30% 0;
                    @media only screen and (min-device-width: 768px)  and (max-device-width: 1024px)  and (-webkit-min-device-pixel-ratio: 1) {
                        //IPAD
                        flex: 50% 0;
                    }

                    .business-sca-column-header{
                        padding-bottom: 20px;
                        font-weight: bold;

                        .column-link{
                            cursor: pointer;
                            text-decoration: none;
                            color: $shades-white;
                        }
                    }

                    .business-sca-column-listItem{
                        padding: 7px 0 7px 0;
                        .column-link {
                            cursor: pointer;
                            text-decoration: none;
                            color: $shades-white;
                        }
                        .column-link:hover{
                            text-decoration: underline;
                        }
                    }

                }
            }

            .copyright-row{
                padding-top: 30px;
                @media (max-width:600px){
                    display: flex;
                    justify-content: center;
                }
            }
        }

        .footerPrivacyPolicy:hover{
            text-decoration: underline;
            cursor: pointer;
        }

    }
}
