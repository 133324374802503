@import '/src/assets/styles/colors';




.header{
    background: $shades-white!important;
    max-width: 100%;

    .usable-width{
        width: var(--usable-width);
        display: flex;
        justify-content: space-between;
        margin: auto;
        align-items: center;
        min-height: 70px;
        max-height: fit-content;
        padding-right: 0px;
        padding-left: 0px;
        background: $shades-white!important;

        #responsive-navbar-nav{
            justify-content: flex-end;
            .option {
                // flex: 0 0 auto;
                padding: 10px 15px;
                cursor: pointer;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                background: $shades-white!important;

                &:hover{
                    color: $primary-color-purple;
                }

                .btn-primary-header{
                    width: fit-content;
                }
            }

            .current-page{
                color: $primary-color-purple;
            }
        }
    }
}
