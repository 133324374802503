@import '/src/assets/styles/colors';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

//TEXT DECORATIONS :=>
.body-bold{
  //styleName: body bold;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: $shades-dark-liver;
}

.body-text{
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.hero-heading{
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 45px;
  letter-spacing: 2%;
  line-height: 53px;
}

.body-large{
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}

.header-2{
  font-size: 31px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
}

.header-1-title{
  //styleName: Header 1/ Title;
  font-size: 37px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: center;
}

.header-3{
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  font-family: 'Roboto';
}

.smaller-text-bold{
  //styleName: smaller text bold;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.smaller-text{
  //styleName: smaller text;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.small-header{
  //styleName: Small Header;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}



// BUTTONS :=>

  .btn-primary-header {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    width: fit-content;
    line-height: 21px;
    padding: 0 24px;
    color: $shades-white;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto';
    letter-spacing: .8px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    outline: none;
    user-select: none;
    transition: all .3s ease-out;
    // box-shadow: 0 2px 5px 0 rgba(0,0,0,0.225);
    background-color: $primary-color-purple;
    border: 1px solid $primary-color-purple;
    box-sizing: border-box;
    border-radius: 5px;

    &:hover {
      text-decoration: none;
      box-shadow: 0 4px 10px 0px rgba(0,0,0,0.225);
      border: 1px solid $primary-color-blue;
      background-color: $primary-color-blue;
    }
  }

  .btn-primary-regular {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 52px;
    width: fit-content;
    line-height: 21px;
    padding: 0 24px;
    color: $shades-white;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto';
    letter-spacing: .8px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    outline: none;
    user-select: none;
    transition: all .3s ease-out;
    background-color: $primary-color-purple;
    border-radius: 4px;

    &:hover {
      text-decoration: none; box-shadow: 0 4px 10px 0px rgba(0,0,0,0.225);
      background-color: $primary-color-blue;
      color: $shades-white;
    }
  }

  .btn-secondary-purple-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 52px;
    width: 160px;
    line-height: 21px;
    padding: 0 1.5rem;
    color: $shades-white;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto';
    letter-spacing: .8px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    outline: none;
    user-select: none;
    transition: all .3s ease-out;
    // box-shadow: 0 2px 5px 0 rgba(0,0,0,0.225);
    background-color: $primary-color-purple;
    left: 20px;
    top: 20px;
    border-radius: 5px;
    border: 1px solid $shades-white;

    &:hover {
      text-decoration: none; box-shadow: 0 4px 10px 0px rgba(0,0,0,0.225);
      border: 1px solid $shades-white;
      background: $shades-white;
      color: $primary-color-purple;
    }
  }

  .btn-secondary-white-bg {
     display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 52px;
    width: 160px;
    line-height: 21px;
    padding: 0 1.5rem;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto';
    letter-spacing: .8px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    outline: none;
    user-select: none;
    transition: all .3s ease-out;
    background-color: $shades-white;
    left: 20px;
    top: 20px;
    border-radius: 5px;
    border: 1px solid $primary-color-purple;
    color: $primary-color-purple;

    &:hover {
      text-decoration: none;
      box-shadow: 0 4px 10px 0px rgba(0,0,0,0.225);
      border: 1px solid $primary-color-blue;
      color: $primary-color-blue;
    }
  }










  :root {
      --usable-width: 90%!important;
      --homepage-padding: 60px 0px 0px 0px;
  }

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  /* Styles */
    :root {
        --usable-width: 90%!important;
    }
  }

  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
  /* Styles */
  }

  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
  /* Styles */
  }

  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  /* Styles */
  }

  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  /* Styles */
  }

  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  /* Styles */
  }
  /**********
  iPad 3
  **********/
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
  /* Styles */
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
      :root {
          --homepage-padding: 120px 0px 120px 0px;
      }
  }
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
      :root {
          --homepage-padding: 120px 0px 120px 0px;
      }
  }

  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
      :root {
          --homepage-padding: 120px 0px 120px 0px;
      }
  }

  /* iPhone 4 ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
  /* Styles */
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
  /* Styles */
  }

  /* iPhone 5 ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
  /* Styles */
  }

  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  /* Styles */
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
  /* Styles */
  }

  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  /* Styles */
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
  /* Styles */
  }

  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  /* Styles */
  }

  /* iPhone X ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
  /* Styles */
  }

  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
  /* Styles */
  }

  /* iPhone XS Max, XR ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
  /* Styles */
  }

  @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
  /* Styles */
  }

  /* Samsung Galaxy S3 ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
  /* Styles */
  }

  @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  /* Styles */
  }

  /* Samsung Galaxy S4 ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
  /* Styles */
  }

  @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
  /* Styles */
  }

  /* Samsung Galaxy S5 ----------- */
  @media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
  /* Styles */
  }

  @media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
  /* Styles */
  }
