@import '/src/assets/styles/colors';

.get-started {
    background: $secondary-color-lavender-blue;
    width: 100%;
    min-height: 230px;
    display: flex;
    vertical-align: middle;
    justify-content: space-evenly;
    padding: 60px 0 60px 0;

    .usable-width {
        display: flex;
        width: var(--usable-width);
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-items: center;
        color: $primary-color-blue;
        flex-direction: column;

        .get-started-div-header {
            margin: 0px auto 15px auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .get-started-div-description {
            margin: 0px auto 30px auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }



        .get-started-actions {
            display: inline-flex;
            width: 400px;

            @media (max-width:600px) {
                width: 100%;
            }

            justify-content: space-evenly;

            a {}
        }
    }
}